.se-card {
  margin-bottom: $layout-03;

  .se-card__inner {
    @include neumorphism-out;
    height: 100%;
    border-radius: $spacing-05;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }

  .se-card__label, .se-card__category {
    @include font(12px,400,normal,uppercase);
    height: $spacing-06;
    display: flex;
    padding: 0 $spacing-03;
    border-radius: $spacing-06;
    align-items: center;
    position: absolute;
    top: $layout-01;
    
    &.se-card__label {
      right: $layout-01;
      background-color: var(--accent-background-color);
    }
    &.se-card__category {
      display: none;
      left: $layout-01;
      background-color: var(--background-color);
    }
  }

  .se-card__image {
    height: carbon--rem(200px);
    display: flex;
    justify-content: center;
    border-radius: $spacing-05 $spacing-05 0 0;
    img {
      margin-top: $layout-02;
      max-width: 60%;
      max-height: 60%;
      align-self: center;
    }
  }

  .se-card__content {
    padding: $spacing-05;
    flex-grow: 1;
  }

  h4 {
    span {
      display: block;

      &.se-card__heading {
        @include font(24px, 700);
      }

      &.se-card__subheading {
        @include font(16px, 400);
      }
    }
  }

  p {
    margin-top: $spacing-05;
  }

  .se-card__actions {
    margin: $spacing-05;
    display: flex;
    justify-content: space-between;
  }
}
