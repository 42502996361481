@import "./node_modules/carbon-components/scss/globals/scss/vars.scss";

@mixin font($size, $weight, $lineheight: "", $transform: "") {
  font-size: carbon--rem($size);
  font-weight: $weight;
  @if $lineheight != "" {
    line-height: $lineheight;
  }
  @if $transform != "" {
    text-transform: $transform;
  }
}

@mixin neumorphism-out {
  background-color: var(--background-color);
  background-image: linear-gradient(
    to right bottom,
    var(--shadow-gradient-light-color),
    var(--shadow-gradient-dark-color)
  );
  background-blend-mode: soft-light;
  background-repeat: no-repeat; // fix for iOS blend mode bug
  border: 0.5px solid var(--shadow-border-color);
  box-shadow: -4px -4px 8px var(--shadow-light-color),
    4px 4px 8px var(--shadow-dark-color);
}
.neumorphism-out {
  @include neumorphism-out;
}

@mixin neumorphism-in {
  background-color: var(--background-color);
  background-image: linear-gradient(
    to right bottom,
    var(--shadow-gradient-dark-color),
    var(--shadow-gradient-light-color)
  );
  background-blend-mode: soft-light;
  background-repeat: no-repeat; // fix for iOS blend mode bug
  border: 0.5px solid var(--shadow-border-color);
  box-shadow: inset -4px -4px 8px var(--shadow-light-color),
    inset 4px 4px 8px var(--shadow-dark-color);
}
.neumorphism-in {
  @include neumorphism-in;
}

.bx--header__nav {
  display: block !important;
}

.bx--content a,
footer a {
  text-decoration: none;
  color: var(--link-color);
  &:not(.button):hover {
    border-bottom: 0.5px solid var(--link-color);
  }
}

.bx--content h3 {
  // @include font(24px, 700);
  @include font(16px, 400, 1, uppercase);
  margin-top: $layout-03;
  margin-bottom: $layout-02;
}

@import "./components/SEHeader/se-header.scss";
@import "./components/SEFooter/se-footer.scss";
@import "./components/SEHero/se-hero.scss";
@import "./components/SECard/se-card.scss";

.bx--btn {
  &.bx--btn--se,
  &.bx--btn--sep,
  &.bx--btn--set {
    color: var(--link-color);
    &.bx--btn--se,
    &.bx--btn--sep {
      @include neumorphism-out;
    }
    &.bx--btn--set {
      background: var(--background-color);
      border: 0.5px solid transparent;
      background-blend-mode: soft-light;
    }
    @include font(16px, 600, normal, uppercase);
    border-radius: $spacing-03;
    padding-left: $spacing-05;
    padding-right: $spacing-05;
    &:hover {
      @include neumorphism-in;
    }
    + .bx--btn--se,
    + .bx--btn--sep,
    + .bx--btn--set {
      margin-left: $layout-01;
    }
    &.bx--btn--sep {
      background: var(--link-color);
      color: var(--background-color);
      &:hover {
        box-shadow: inset -4px -4px 8px rgba(255, 255, 255, 0.1),
          inset 4px 4px 8px rgba(18, 12, 48, 0.1);
      }
    }
  }
}

@import "./contents/Extensions/extensions.scss";
@import "./contents/Knowledge/knowledge.scss";
