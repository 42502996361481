.se-hero {
  margin-bottom: $layout-05;

  &.bx--grid {
    padding-right: $layout-03;
    padding-left: $layout-03;
  }

  @media (min-width: 42rem) {
    @include neumorphism-in;
    padding-top: $layout-05;
    padding-bottom: $layout-05;

    div.bx--row {
      flex-direction: row-reverse;
    }
  }

  @media (min-width: 66rem) {
    .bx--offset-lg-1 {
      margin-left: 0;
      margin-right: 6.25%;
    }
  }

  .se-hero__label,
  .se-hero__category {
    @include font(12px, 400, normal, uppercase);
    height: $spacing-06;
    display: flex;
    padding: 0 $spacing-03;
    border-radius: $spacing-06;
    align-items: center;
    position: absolute;
    top: $layout-01;

    &.se-hero__label {
      right: $layout-01;
      background-color: var(--accent-background-color);
    }
    &.se-hero__category {
      display: none;
      left: $layout-01;
      background-color: var(--background-color);
    }
  }

  .se-hero__image {
    display: flex;
    justify-content: center;
    img {
      width: 1;
      max-width: 60%;
      max-height: 60%;
      align-self: center;
    }
  }

  .se-hero__content {
    margin-top: $layout-01;
    @media (min-width: 42rem) {
      margin-top: 0;
    }
  }

  h2 {
    span {
      display: block;

      &.se-hero__topline {
        @include font(16px, 400, 1, uppercase);
        margin-bottom: $spacing-03;
      }

      &.se-hero__heading {
        @include font(80px, 700, 1);
      }

      &.se-hero__subheading {
        @include font(24px, 400, 1.25);
        margin-bottom: $spacing-05;
      }
    }
  }

  p {
    margin-bottom: $spacing-07;
  }

  .se-hero__actions {
    display: flex;
    justify-content: space-between;
  }
}
