footer.bx--grid {
  margin-top: $layout-03;
  margin-bottom: $layout-02;

  @media (min-width: 66rem) {
    .right {
      text-align: right;
    }
  }

  .bx--col {
    margin-bottom: $spacing-03;
  }
}
