.se-header {
  &.bx--header {
    height: auto;
    position: initial;
    background-color: initial;
    border-bottom: none;
    overflow: scroll;
    flex-wrap: wrap;
    @media (min-width: 42rem) {
      flex-wrap: nowrap;
    }
  }

  img.se-header__logo {
    height: $layout-05;
    width: $layout-05;
    margin-bottom: $layout-01;
    @media (min-width: 66rem) {
      height: $layout-06;
      width: $layout-06;
      margin-bottom: $layout-03;
    }
  }

  a.bx--header__name {
    @include font(24px, 700, 1);
    @media (min-width: 66rem) {
      @include font(32px, 700);
    }
    padding-right: 0;
    @media (min-width: 42rem) {
      padding-right: $spacing-07;
    }
    color: var(--text-color);
    &:hover {
      color: var(--text-color);
    }
  }

  nav.bx--header__nav {
    &::before {
      display: none;
    }
    padding-left: 0;
    flex-grow: 1;
    flex-basis: 100%;
    margin-bottom: $layout-01;
    @media (min-width: 42rem) {
      flex-basis: auto;
      margin-bottom: 0;
    }

    ul.bx--header__menu-bar {
      justify-content: center;
      @media (min-width: 42rem) {
        justify-content: flex-end;
      }

      li {
        height: $layout-04;
        margin-left: $layout-01;
        &:first-child {
          margin-left: 0;
        }
        a {
          padding: 0 $spacing-05;
          @media (min-width: 42rem) {
            padding: 0 $spacing-07;
          }
          border-radius: $spacing-07;
          @include neumorphism-out;
          @include font(16px, 600, normal, uppercase);
          @media (min-width: 66rem) {
            @include font(20px, 600, normal, uppercase);
          }
          color: var(--link-color);

          &:hover,
          &:active,
          &:focus,
          &.active {
            color: var(--link-color);
            @include neumorphism-in;
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}
