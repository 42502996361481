// Feature flags
$feature-flags: (
  ui-shell: true,
  grid-columns-16: true
);

// @import "carbon-components/scss/globals/scss/styles.scss";

// Global styles
@import "./node_modules/carbon-components/scss/globals/grid/grid";

// // Carbon components
@import "./node_modules/carbon-components/scss/components/button/button";
@import "./node_modules/carbon-components/scss/components/ui-shell/ui-shell";

:root {
  color-scheme: light dark;
  --text-color: #120c30;
  --link-color: #ed7401;
  --background-color: #f0f2f4;
  --accent-background-color: #fdad00;
  --shadow-light-color: rgba(255, 255, 255, 1);
  --shadow-dark-color: rgba(18, 12, 48, 0.1);
  --shadow-border-color: rgba(255, 255, 255, 0.2);
  --shadow-gradient-light-color: rgba(255, 255, 255, 0.4);
  --shadow-gradient-dark-color: rgba(0, 0, 0, 0.4);
}

@media (prefers-color-scheme: dark) {
  :-root {
    --text-color: #f0f2f4;
    --link-color: #ed7401;
    --background-color: #120c30;
    --accent-background-color: #fdad00;
    --shadow-light-color: rgba(255, 255, 255, 0.05);
    --shadow-dark-color: rgba(18, 12, 48, 1);
    --shadow-border-color: rgba(0, 0, 0, 0.4);
    --shadow-gradient-opacity: 0.4;
    --shadow-gradient-light-color: rgba(255, 255, 255, 0.4);
    --shadow-gradient-dark-color: rgba(0, 0, 0, 0.4);
  }
}

body {
  background-color: var(--background-color);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: var(--text-color);
}

// Remove overrides once Carbon bugs are fixed upstream.

/// The React <Content /> component uses the `main` element which IE11 doesn't recognize
/// @link https://github.com/carbon-design-system/carbon/issues/2789
main {
  display: block;
}

/// Need grid option to not add page gutters at large viewports, to also use when nesting grids
/// @link https://github.com/carbon-design-system/carbon/issues/2792
@media (min-width: 42rem) {
  .bx--grid--no-gutter {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

/// Padding was introduced in 10.3.0, needs an option to let grid set the viewport gutter
/// @link https://github.com/carbon-design-system/carbon/issues/3010
.bx--content {
  background: initial;
  margin-top: 0 !important;
  padding: 0 !important;
}
